* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Effra Medium", sans-serif !important;
}

#root {
	background-color: #292929;
}

body {
	background-color: #292929;
	padding-right: 0px !important;
	overflow: auto !important;
}

.MuiButtonBase-root.MuiMenuItem-root {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	background-color: transparent;
	outline: 0;
	border: 0;
	margin: 0;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	-moz-appearance: none;
	-webkit-appearance: none;
	-webkit-text-decoration: none;
	text-decoration: none;
	color: inherit;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5;
	letter-spacing: 0.00938em;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	-webkit-text-decoration: none;
	text-decoration: none;
	min-height: 48px;
	padding-top: 6px;
	padding-bottom: 6px;
	box-sizing: border-box;
	white-space: nowrap;
	padding-left: 16px;
	padding-right: 16px;
}

h1,
h2,
h3,
h4,
h5 {
	color: white;
}

p {
	margin-top: 0;
	margin-bottom: 0;
}

/* ul {
  list-style-type: none;
} */
.public-DraftStyleDefault-ul,
.public-DraftStyleDefault-ol {
	margin-left: 30px;
}

.public-DraftStyleDefault-block {
	margin-left: 5px;
}

.page-container {
	padding-top: 40px;
}

.page-container > div:not(:first-child) {
	margin-top: 15px;
}

.rti--container {
	width: 100%;
}

.rti--tag {
	background-color: #78f73b !important;
	color: black !important;
	height: 30px;
	border-radius: 20px !important;
	padding-left: 10px !important;
}

.rti--tag button {
	position: relative !important;
	background: #00000099 !important;
	color: #78f73b !important;
	border: 0 !important;
	margin: 0px 5px;
	border-radius: 50% !important;
	cursor: pointer !important;
	line-height: inherit !important;
	padding: 0px 5px !important;
}

.RoleInput .MuiButtonBase-root {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	background-color: transparent;
	outline: 0;
	border: 0;
	margin: 0;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	-moz-appearance: none;
	-webkit-appearance: none;
	-webkit-text-decoration: none;
	text-decoration: none;
	color: inherit;
	text-align: center;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	font-size: 1.5rem;
	padding: 8px;
	border-radius: 50%;
	overflow: visible;
	color: #fff;
	-webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	padding: 2px;
	margin-right: -2px;
}

.MuiAutocomplete-paper {
	color: white !important;
	background-color: #121212 !important;
}

.RoleInput .MuiIconButton-root {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	background-color: transparent;
	outline: 0;
	border: 0;
	margin: 0;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	-moz-appearance: none;
	-webkit-appearance: none;
	-webkit-text-decoration: none;
	text-decoration: none;
	color: inherit;
	text-align: center;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	font-size: 1.5rem;
	padding: 8px;
	border-radius: 50%;
	overflow: visible;
	color: #fff;
	-webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	padding: 2px;
	margin-right: -2px;
}

.RoleInput .MuiAutocomplete-clearIndicator {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	background-color: transparent;
	outline: 0;
	border: 0;
	margin: 0;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	-moz-appearance: none;
	-webkit-appearance: none;
	-webkit-text-decoration: none;
	text-decoration: none;
	color: inherit;
	text-align: center;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	font-size: 1.5rem;
	padding: 8px;
	border-radius: 50%;
	overflow: visible;
	color: #fff;
	-webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	padding: 2px;
	margin-right: -2px;
}

.RoleInput .MuiAutocomplete-popupIndicator {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	background-color: transparent;
	outline: 0;
	border: 0;
	margin: 0;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	-moz-appearance: none;
	-webkit-appearance: none;
	-webkit-text-decoration: none;
	text-decoration: none;
	color: inherit;
	text-align: center;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	font-size: 1.5rem;
	padding: 8px;
	border-radius: 50%;
	overflow: visible;
	color: #fff;
	-webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	padding: 2px;
	margin-right: -2px;
}

.MuiTablePagination-spacer {
	flex: 0 !important;
}

.rti--input {
	border: 0 !important;
	outline: 0 !important;
	font-size: inherit !important;
	line-height: inherit !important;
	min-width: 90px !important;
	width: unset !important;
	height: 30px !important;
	color: white !important;
	border-radius: 5px !important;
	background-color: #292929 !important;
	padding-left: 15px !important;
}

.Blog_content {
	display: flex !important;
	flex-direction: column !important;
	align-content: center;
	justify-content: center;
	align-items: center;
	min-width: 100%;
	max-width: 100%;
}

.quill {
	min-width: 100%;
	max-width: 100%;
	margin-left: 0px !important;
}

.ql-toolbar {
	border-radius: 5px;
}

.ql-editor {
	height: 400px !important;
	border-radius: 5px;
	background-color: #292929;
}

/* .ql-picker{
  color:white !important
}
.ql-expanded{
  color:black !important
} */

.ql-container {
	height: 400px !important;
	border: 0px !important;
	background-color: #292929;
}

a {
	text-decoration: none;
}

.maincontainer {
	display: flex;
}

legend {
	display: none !important;
}

.video-react-big-play-button {
	display: none !important;
}

/* .navbar{
  background-color: black;
  height: 80px;
  display:flex;
  justify-content: flex-start;
  align-items: center;
} */

.menu-bars {
	margin-left: 2rem;
	font-size: 2rem;
	background: none;
}

.MuiList-root.MuiMenu-list {
	color: white;
}

.nav-menu {
	background-color: #191919;
	width: 250px;
	height: 100vh;
	display: flex;
	justify-content: center;
	position: relative;
	top: 0;
	/*   left:-100%; */
	transition: 850ms;
}

.nav-menu.active {
	left: 0;
	transition: 350ms;
}

.nav-menu.disable {
	// display: none;
	transition: 350ms;
	transform: translateX(-100%);
}

.create {
	transition: all 0.18s;
}

.create:hover {
	background-color: rgb(79, 175, 35) !important;
}

.create:active {
	background-color: rgb(184, 255, 152) !important;
}

.ModelProfilePage_arrow_back:hover,
.ModelProfilePage_arrow_next:hover {
	background-color: rgba(255, 255, 255, 0.0005) !important;
}

.ModelProfilePage_arrow_back:active,
.ModelProfilePage_arrow_next:active {
	background-color: rgba(255, 255, 255, 0.002) !important;
}

.hover {
	transition: all 0.19s ease-out;
}

.hover:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.hover:active {
	background-color: rgba(255, 255, 255, 0.2);
}

.nav-menu {
	background-color: #191919;
	width: 250px;
	height: 100vh;
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
	/*   left:-100%; */
	transition: 850ms;
}

.nav-menu.active {
	left: 0;
	transition: 350ms;
}

.nav-text {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 11px 0px;
	list-style: none;
	height: fit-content;
	width: 100%;
}

.nav-text a {
	text-decoration: none;
	color: whitesmoke;
	font-size: 18px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 4px 16px;
}

li.nav-text:hover a {
	color: #78f73b;
}

li.nav-text:hover a.active {
	color: black;
}

.nav-text a.active {
	background-color: #78f73b;
	color: black;
}

.nav-menu-items li.nav-top {
	padding: 10px 0;
	background: black;
}

li.nav-top a.avatarBlock {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-content: flex-start;
	flex-direction: column;
}

/* Specific styles for active links */
.link-active a {
	background-color: orange;
}

.nav-menu-items {
	width: 100%;
}

.AllRoomGrid {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
}

.LiveShow {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: flex-start;
	flex-wrap: nowrap;
	flex-direction: column;
	min-width: 500px;
	color: white;
}

.LoginForm {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: flex-start;
	flex-wrap: nowrap;
	flex-direction: column;
	min-width: 350px;
	color: white;
}

.chatModeration {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	flex-direction: row !important;
	min-width: 400px;
	color: white;
	align-content: center;
}

.LiveShow_Geo {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: flex-start;
	flex-wrap: nowrap;
	flex-direction: row;
	min-width: 400px;
	color: white;
}

.Pools {
	margin: 0 !important;
	padding: 0;
	display: flex;
	align-items: center;
	flex-direction: row;
	min-width: 650px;
	color: white;
	flex-wrap: nowrap;
	padding-bottom: 40px;
}

.modal18_terms {
	text-align: left;
	font: normal normal medium 18px/24px Roboto;
	letter-spacing: 0px;
	color: #ffffff !important;
	opacity: 1;
}

.modal18_terms {
	text-align: left;
	font: normal normal medium 18px/24px Roboto;
	letter-spacing: 0px;
	color: #ffffff !important;
	opacity: 1;
}

.modal18_terms a:hover {
	color: unset;
}

.modal18_terms::selection {
	text-align: left;
	font: normal normal medium 18px/24px Roboto;
	letter-spacing: 0px;
	color: #ffffff !important;
	opacity: 1;
}

.AllRoomStack {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.AllRoomBlock_list {
	width: 100%;
	/*display: flex;
	flex-wrap: wrap;*/
	margin: auto 0px 10px !important;
}

.AllRoomBlock_list_modal {
	width: 90%;
	display: flex;
	margin: auto 0px 10px !important;
	flex-direction: column;
}

.AllRoomBlock_list_images {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin: auto 0px 10px !important;
	padding-left: 10px !important;
}

.ant-upload.ant-upload-select-picture-card {
	width: 104px;
	height: 104px;
	margin-right: 8px;
	margin-bottom: 8px;
	text-align: center;
	vertical-align: top;
	background-color: #393939;
	border: 1px #d9d9d9;
	border-radius: 2px;
	cursor: pointer;
	transition: border-color 0.3s;
}

.ant-upload-list-picture-card .ant-upload-list-item-name {
	color: white;
}

/* .load {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #000000e8;
  top: 0;
  left: 0;
  z-index: 2;
  color: green;
  font-size: 200%;
  padding: 30% 0;
} */
.loader-container {
	width: 100%;
	height: 100%;
	position: absolute;
	background: #050505ad;
	top: 0;
	left: 0;
	z-index: 3;
	color: #78f73b;
	font-size: 200%;
	padding: 30% 0;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.spinner {
	width: 64px;
	height: 64px;
	border: 5px solid;
	border-color: #78f73b transparent #78f73b transparent;
	border-radius: 50%;
	animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.AllRoomBlock_list_chats {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin: auto 0px 10px !important;
}

.AllRoomBlock_grid {
	padding: 10px 0;
	display: flex;
	justify-content: center;
}

.camera_details .MuiBackdrop-root {
	opacity: 0 !important;
}

.event_details .MuiBackdrop-root {
	opacity: 0 !important;
}

.MuiModal-root .BackButton {
	display: flex;
	/* justify-content: flex-end; */
	/* justify-items: flex-end; */
	/* padding: 0px 0px 0px; */
	/* margin: 0; */
	/* margin: -18px 321px -22px; */
	position: absolute;
	right: 0%;
	top: 0%;
}

.LazyInput {
	max-width: 400px;
	min-width: 200px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	background-color: #1a1a1a;
	margin-top: 5px;
	padding: 5px;
	border-radius: 10px;
	width: 90%;
}

.ScheduleInput {
	max-width: 400px;
	width: 150px !important;
	display: flex !important;
	justify-content: center;
	justify-items: center;
	flex-direction: column;
	background-color: #1a1a1a;
	margin-top: 5px;
	padding: 5px;
	border-radius: 10px;
	width: 90%;
	color: white;
	max-height: 30px;
}

.ScheduleInputStatus {
	max-width: 400px;
	width: 100% !important;
	min-width: 250px;
	display: flex !important;
	justify-content: center;
	justify-items: center;
	flex-direction: column;
	background-color: #1a1a1a;
	margin-top: 5px;
	padding: 5px 8px;
	border-radius: 3px;
	width: 90%;
	color: white !important;
}

.ProfileRoleInput {
	max-width: 400px;
	width: 200px !important;
	display: flex !important;
	justify-content: center;
	justify-items: center;
	flex-direction: column;
	background-color: #1a1a1a;
	margin-top: 5px;
	padding: 5px;
	border-radius: 3px;
	width: 90%;
	color: white;
	max-height: 40px;
}

.ScheduleInput .Mui-disabled {
	opacity: 1 !important;
	-webkit-text-fill-color: white !important;
}

.ScheduleInputStatus .Mui-disabled {
	opacity: 1 !important;
	-webkit-text-fill-color: white !important;
}

.ProfileRoleInput .Mui-disabled {
	opacity: 1 !important;
	-webkit-text-fill-color: white !important;
}

.RoleInput {
	max-width: 400px;
	width: 250px !important;
	display: flex;
	justify-content: center;
	justify-items: center;
	flex-direction: column;
	background-color: #1a1a1a;
	color: white;
	margin-top: 5px;
	padding: 5px;
	width: 90%;
}

.RoleInput .Mui-disabled {
	opacity: 1 !important;
	-webkit-text-fill-color: white !important;
}

.TableCellTime {
	display: flex !important;
	justify-content: center;
	justify-items: center;
	flex-direction: column;
	color: #000000 !important;
	margin-top: 22px;
	padding: 10px !important;
	border-radius: 10px;
	background-color: #78f73b;
	max-height: 30px;
}

tr {
	height: 10px;
	cursor: pointer;
}

tr td {
	height: auto !important;
}

.tags-input {
	.MuiChip-root {
		background-color: #78f73b;
		color: #000000de;
	}

	.MuiChip-deleteIcon {
		color: #00000099 !important;
	}

	.MuiInputBase-root:before {
		border: 0 !important;
	}

	.MuiInputBase-root:after {
		border: 0 !important;
	}
}

.LazyInput .MuiChip-root {
	background-color: #78f73b;
	color: #000000de;
}

.LazyInput .MuiChip-deleteIcon {
	color: #00000099 !important;
}

.GalleryInput {
	max-width: 400px;
	min-width: 200px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	background-color: white;
	color: black;
	border-radius: 4px;
}

.GalleryInput .MuiAutocomplete-clearIndicator {
	color: black !important;
}

.GalleryInput .MuiInput-root {
	padding: 4px !important;
	color: black !important;
}

.GalleryInput .MuiAutocomplete-popupIndicator {
	color: black !important;
}

.transition {
	transition: all 0.19s ease-out;
}

.ChatListElem_wrap_live {
	min-width: 200px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	background-color: #1a1a1a;
	margin-top: 5px;
	border-radius: 5px;
	width: 90%;
}

.ChatListElem_wrap_live .msg {
	display: flex;
	justify-content: center;
	padding: 5px 12px;
	color: whitesmoke !important;
}

.ChatListElem_wrap .msg {
	padding: 5px;
}

/* .ImgPlace {
  display: flex;
  color: whitesmoke;
} */

.ImgPlace .img {
	border-radius: 50%;
}

#root .MuiContainer-root {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.Search {
	flex: 1;
	display: flex;
	justify-content: space-evenly;
	flex-direction: row;
	word-wrap: break-word;
	max-width: 400px;
	flex-wrap: nowrap;
	align-items: center;
}

.MuiInput-underline.Mui-disabled:before {
	border-bottom: 0;
}

/* #root .MuiButtonBase-root label {
  color: rgba(255, 255, 255, 0.26);
} */

.success {
	background-color: #388e3c !important;
	color: white !important;
}

.error {
	background-color: #d32f2f !important;
	color: white !important;
}

.SchrollBar::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: #555555;
}

.SchrollBar::-webkit-scrollbar {
	-webkit-appearance: none;
	background-color: #212121;
}

.SchrollBar::-webkit-scrollbar:vertical {
	width: 6px;
	background-color: #212121;
}

.SchrollBar::-webkit-scrollbar:horizontal {
	display: none;
}

.SchrollBar tr:nth-child(even) {
	background-color: #363535;
}

.SchrollBar .MuiPaper-root .MuiTableContainer-root {
	background-color: #555555;
}

.SchrollBar_Geo::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: #555555;
}

.SchrollBar_Geo::-webkit-scrollbar {
	-webkit-appearance: none;
	background-color: #212121;
}

.SchrollBar_Geo::-webkit-scrollbar:vertical {
	width: 6px;
	background-color: #212121;
}

.SchrollBar_Geo::-webkit-scrollbar:horizontal {
	display: none;
}

.SchrollBar_Geo tr {
	color: rgba(0, 0, 0, 0.87);
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	background-color: #121212;
}

.SchrollBar_Geo tr:nth-child(odd) {
	background-color: rgb(0 0 0 / 80%);
}

.SchrollBar_Geo::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: #555555;
}

.SearchBar {
	background-color: #1a1a1a !important;
	font-size: 22px;
}

.SearchBar button {
	color: rgb(255 255 255);
	transition:
		transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.SearchBar button.ForwardRef-iconButtonHidden {
	transform: scale(0, 0);
}

.SearchBar input {
	color: white !important;
}

.Search_box {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
}

input.inputBox {
	font-size: 20px;
	padding: 5px 8px 4px 8px;
	border-radius: 3px;
	background-color: #1a1a1a;
	color: white;
	border-width: 0px;
	width: 150px;
}

.calendarWrap {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	align-content: center;
}

.calendarElement {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 40px;
	border: 1px solid #ccc;
	z-index: 999;
}

.LazyInput {
	max-width: 400px;
	min-width: 200px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	background-color: #1a1a1a;
	margin-top: 5px;
	padding: 5px;
	border-radius: 10px;
	width: 90%;
}

/* .RoleInput button {
  position: relative !important;
  color: rgb(255 255 255);
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    
}

.RoleInput button.ForwardRef-iconButtonHidden {
  transform: scale(0, 0);
}
.RoleInput input {
  color: white !important;
} */

/* .RoleInput .MuiAutocomplete-endAdornment{
  position: absolute;
  right: 0;
  top: calc(50% - 14px);
} */

/* .RoleInput .Mui-disabled {
  position: relative !important;
  opacity: 1 !important;
  -webkit-text-fill-color: white !important ;
} */

.SchrollBarShedule::-webkit-scrollbar {
	-webkit-appearance: none;
	background-color: #212121;
}

.SchrollBarShedule::-webkit-scrollbar:vertical {
	width: 6px;
	background-color: #212121;
}

.SchrollBarShedule::-webkit-scrollbar:horizontal {
	display: none;
}

.MuiPaper-root .MuiPaper-root-MuiTableContainer-root {
	background-color: #121212;
}

.SchrollBarShedule::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: #555555;
}

.Search_role {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
}

input.inputBox {
	font-size: 20px;
	padding: 5px 8px 4px 8px;
	border-radius: 3px;
	background-color: #1a1a1a;
	color: white;
	border-width: 0px;
	width: 150px;
}

#root .MainStreamScreen {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

#root .GeoBlock_elements {
	color: white;
	margin-right: 10px;
	min-width: 20px;
	text-align: right;
	font-size: 15px;
}

#root .GeoBlock_row {
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	align-items: center;
	min-width: 500px;
}

#root .ModelProfilePage_arrow {
	border-radius: 20px;
	font-size: 14px;
	padding: 5px 15px;
	font-weight: 800;
	border: 1px solid #ccc;
	color: #ccc;
}

#root .PromoPage_arrow {
	border-radius: 20px;
	font-size: 14px;
	padding: 5px 15px;
	font-weight: 800;
	border: 1px solid #ccc;
	color: #ccc;
	max-width: fit-content;
	margin-bottom: 20px;
}

.ModelProfilePage_wrap {
	display: flex;
	padding-bottom: 10px;
}

.ModelProfilePage_name {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 10px 0;
}

.ModelProfilePage_name .grid {
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
	min-width: 130px;
}

.ModelProfilePage_main {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
}

#root .ModelProfilePage_elements {
	color: white;
	margin-right: 10px;
	min-width: 200px;
	text-align: right;
	font-size: 13px;
	margin-bottom: 0px;
}

.Blog_elements {
	color: white;
	margin-right: 10px;
	min-width: 100px;
	text-align: right;
	font-size: 13px;
	margin-bottom: 0px;
}

.Blog_main {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	flex-direction: column;
	align-items: flex-start;
}

#root .ModelProfilePage_row {
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

#root .ModelProfilePage_row .content {
	color: #ffffff;
	min-width: 180px;
	max-width: 420px;
	padding-top: 10px;
}

.ModelProfilePage_media {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: flex-end;
}

.Pool_wrap {
	display: flex;
	padding-bottom: 10px;
	min-width: 100%;
}

.Pool_main {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
}

#root .Pools_row {
	margin-top: 10px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
	min-width: 100%;
}

#root .Galleries_row {
	margin-top: 10px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-end;
	align-items: center;
	min-width: 100%;
}

.Pools_input_text {
	background-color: #1d1d1d;
	font-size: 16px;
	padding: 5px 8px 4px 8px;
	border-radius: 5px;
	color: white;
	border-width: 0px;
	width: 100%;
	min-width: 100%;
	height: 40px;
}

.Promos_input_text {
	background-color: #1d1d1d;
	font-size: 16px;
	padding: 5px 8px 4px 8px;
	border-radius: 5px;
	color: white;
	border-width: 0px;
	width: 100%;
	max-width: 100%;
	min-height: 40px;
	/*   height: 40px; */
}

.PayoutSelect {
	width: 30%;
	height: 35px;
	background-color: white;
	color: black !important;
}

.PayoutSelect .MuiSelect-icon {
	color: black;
}

.PayoutSelect .MuiOutlinedInput-notchedOutline {
	bottom: unset !important;
	top: unset !important;
	border-color: transparent !important;
}

.MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-width: 2px;
}

.checkboxUI {
	svg {
		color: #191919;
		background-color: #2c2c2c;
		border-radius: 3px;
		width: 0.85em;
		height: 0.85em;
		margin-left: 2px;
		margin-bottom: 3.6px;
		margin-right: 1.7px;
	}

	.Mui-checked svg {
		color: #78f73b !important;
		background-color: unset !important;
		width: 1em !important;
		height: 1em !important;
		margin-left: unset !important;
		margin-bottom: unset !important;
		margin-right: unset !important;
	}
}

.char-h,
.char-m,
.char-s {
	color: #78f73b;
}

.Pools_input_text .Mui-disabled {
	opacity: 1 !important;
	-webkit-text-fill-color: #ffffff !important;
}

.Pools_input_credits {
	background-color: #1d1d1d;
	margin-left: 5px;
	font-size: 16px;
	padding: 5px 8px 4px 8px;
	border-radius: 5px;
	color: white;
	border-width: 0px;
	width: 74px;
	height: 30px;
}

.Pools_input_time {
	background-color: #1d1d1d;
	margin-left: 5px;
	font-size: 16px;
	padding: 5px 4px 4px 4px;
	border-radius: 5px;
	color: white !important;
	border-width: 0px;
	width: 43px;
	height: 30px;
}

.PoolLazyInput {
	display: flex;
	flex-direction: column;
	background-color: #1d1d1d;
	padding: 5px;
	border-radius: 5px;
	width: 100%;
	height: fit-content;
	min-width: 40px;
}

.PoolLazyInput .MuiChip-root {
	background-color: #78f73b;
	color: #000000de;
}

.disable .MuiAutocomplete-endAdornment {
	display: none;
}

.PoolLazyInput .MuiChip-root.Mui-disabled {
	background-color: #78f73b;
	color: #000000de;
	opacity: 1 !important;
}

.PoolLazyInput .MuiChip-deleteIcon {
	color: #00000099 !important;
}

.PoolModal {
	outline: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #000000 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 40px #00000083;
	border-radius: 5px;
}

.Pool_Table_info {
	border-bottom: none;
	color: rgb(120, 247, 59);
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
}

.Pool_Table_icons {
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: flex-end;
}

#root .PoolContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
	margin-top: 0px;
	padding: 0px 10px;
}

#root .ProvileContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
	background-color: #212121;
}

::-webkit-scrollbar:vertical {
	width: 6px;
	background-color: #212121;
}

::-webkit-scrollbar:horizontal {
	display: none;
}

.MuiPaper-root .MuiPaper-root-MuiTableContainer-root {
	background-color: #121212;
}

::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: #555555;
}

.inputBoxProfile {
	background-color: black;
	font-size: 20px;
	padding: 5px 8px 4px 8px;
	border-radius: 3px;
	color: white;
	border-width: 0px;
	width: 100%;
	min-width: 250px;
}

.inputBoxProfile .Mui-disabled {
	opacity: 1 !important;
	-webkit-text-fill-color: white !important;
}

.inputBoxGeo {
	background-color: black;
	font-size: 20px;
	padding: 5px 8px 4px 8px;
	border-radius: 5px;
	color: white;
	border-width: 0px;
	width: 200px;
}

.DashBoard_box {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-end;
}

.Info_box {
	display: flex;
	/*   align-items: center; */
	justify-content: center;
	width: 170px;
	height: 100px;
	background-color: black;
	color: white;
	border-radius: 5px;
	opacity: 1;
	flex-direction: column;
	justify-content: center;
}

.Info_box .MuiTypography-root {
	margin-bottom: unset !important;
}

#root .ModelProfilePage_arrow_back {
	display: flex;
	align-items: center;
	align-content: flex-start;
	justify-items: center;
	width: 150px;
	height: 40px;
	background-color: black;
	color: white;
	flex-direction: row;
	justify-content: flex-start;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 800;
	text-transform: none;
}

#root .ModelProfilePage_arrow_next {
	display: flex;
	align-items: center;
	align-content: flex-start;
	justify-items: center;
	width: 150px;
	height: 40px;
	background-color: black;
	color: white;
	flex-direction: row;
	justify-content: flex-end;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 800;
	text-transform: none;
}

.Date {
	display: flex;
	align-items: center;
	align-content: center;
	justify-items: center;
	height: 40px;
	background-color: black;
	color: white;
	border-radius: 5px;
	opacity: 1;
	flex-direction: row;
	justify-content: center;
	text-transform: none;
}

#root .UserProfilePage_elements {
	color: white;
	margin-right: 10px;
	min-width: 200px;
	text-align: right;
	font-size: 20px;
}

.userProfile_grid {
	width: 100%;
	display: flex;
	flex-direction: row;
	padding-bottom: 20px;
}

.cameraCard {
	max-height: 90px;
	min-height: 90;
	min-width: 150px;
	margin: 5px;
	border: 5px solid transparent;
	opacity: 1;
}

.cameraCardModal {
	width: 100%;
	max-height: 90px;
	min-width: 150px;
	margin: 5px;
	border: 5px solid transparent;
	opacity: 1;
	position: relative;
}

.MuiButtonBase-root.Cameras_edit,
.MuiButtonBase-root.Cameras_edit:hover {
	background: #77f73b 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
	opacity: 1;
	color: #000000;
	margin: 0;
	margin-bottom: 10px;
}

.cameraCard.showInHome_logged {
	border: 5px solid #78f73b;
	border-radius: 5px 0px 5px 5px;
}

.cameraCard.showInHome_nonlogged {
	border: 5px solid #ff0080;
	border-radius: 5px 0px 5px 5px;
}

.cameraCard.showInHome_nonlogged.showInHome_logged .badge {
	background: linear-gradient(90deg, #ff0080 50%, #78f73b 50%);
}

.cameraCard.showInHome_nonlogged.showInHome_logged {
	box-shadow:
		0 0 9px 5px #ff0080,
		0 0 0px 8px #78f73b;
}

.cameraCard .badge {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0 5px;
	color: black;
}

.camera_wrap_modal {
	display: flex;
}

.cameraCard.showInHome_logged .badge {
	background: #78f73b;
	border-radius: 0px 0px 0px 5px;
}

.cameraCard.showInHome_nonlogged .badge {
	background: #ff0080;
	border-radius: 0px 0px 0px 5px;
}

.modal {
	border-radius: 10px;
	background: #222222 0% 0% no-repeat padding-box;
	box-shadow: 0 0 2rem 12px rgb(0 0 0 / 50%);
	border: 1px solid rgb(18, 18, 18);
}

.modal18 {
	background: #ed2779 0% 0% no-repeat padding-box;
	box-shadow: 0px 20px 20px #00000029;
	border-radius: 5px;
	opacity: 1;
	height: 800px;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	top: 50%;
	left: 50%;
	width: 400px;
}

.draggable {
	/* top: auto !important; */
	left: auto !important;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.modal18_description {
	text-align: center;
	font: normal normal bold 22px/27px Effra;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	font-size: 22px;
}

.modal18_terms {
	text-align: center;
	font: normal normal normal 16px/19px Effra;
	letter-spacing: 0px;
	color: #ffffff !important;
	opacity: 1;
}

.modal18_grid {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 20px;
}

.modal18_grid .modal18_terms_grid {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.MuiButtonBase-root.modal18_mommy_button,
.MuiButtonBase-root.modal18_mommy_button:hover {
	box-shadow: 0px 3px 6px #00000029;
	border: 2px solid #ffffff;
	border-radius: 5px;
	opacity: 1;
	color: #ffffff;
	margin: 0;
	margin-bottom: 10px;
	border-color: #ffffff;
}

.MuiButtonBase-root.modal18_mommy_confirm,
.MuiButtonBase-root.modal18_mommy_confirm:hover {
	background: #77f73b 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
	opacity: 1;
	color: #000000;
	margin: 0;
	margin-bottom: 10px;
}

.BadgeText {
	position: absolute;
	padding: 0px 5px;
	margin-bottom: 0px !important;
	bottom: 0;
	right: 0;
}
.BadgeButton {
	position: absolute;
	padding: 0px 5px;
	margin-bottom: 0px !important;
	bottom: 0;
	right: 0;
	left:0;
	text-align: center;
	background: #d4b106;
	color: #000000;
}

.BadgeText_common {
	position: absolute;
	padding: 0px 5px;
	margin-bottom: 5px !important;
	bottom: 0;
	right: 0;
	text-align: right;
}

.BadgeTextModal {
	position: absolute;
	padding: 0px 5px;
	margin-bottom: 0px !important;
	bottom: 0;
	right: 0;
	color: white;
}

.CurrentOnline {
	position: absolute;
	padding: 0px 5px;
	margin-bottom: 0px !important;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	color: white;
	background: #212121;
	border-radius: 0px 5px 0px 0px;
	opacity: 0.7;
}

.CurrentOnline_common {
	position: absolute;
	padding: 0px 5px;
	margin-bottom: 5px !important;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	color: white;
	background: #212121;
	border-radius: 0px 5px 0px 0px;
	opacity: 0.7;
}

.Recording {
	position: absolute;
	padding: 0px;
	margin-bottom: 0px !important;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	background-color: white;
	/*   background: #212121;
  border-radius: 0px 0px 5px 0px; */
	border-radius: 5px;
	padding: 0px 5px;
	opacity: 1;
}

.Recording_common {
	position: absolute;
	padding: 0px;
	margin-bottom: 5px !important;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	background-color: red;
	border-radius: 5px;
	/*   background: #212121;
  border-radius: 0px 0px 5px 0px; */
	opacity: 1;
	padding: 0px 5px;
}

.CurrentOnline_Modal {
	position: absolute;
	padding: 0px 5px;
	margin-bottom: 0px !important;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	color: white;
	background: #212121;
	border-radius: 0px 5px 0px 0px;
	opacity: 0.7;
}

.InputContainer {
	display: flex !important;
	flex-direction: row !important;
	align-content: center !important;
	justify-content: center ;
	align-items: baseline !important;
}

.rdrInputRanges {
	padding: 10px 0;
	color: black;
}

.rdrStaticRangeLabel {
	display: block;
	outline: 0;
	line-height: 18px;
	padding: 10px 20px;
	text-align: left;
	color: black;
}

.CardListMini::-webkit-scrollbar {
	-webkit-appearance: none;
	background-color: #212121;
}

.CardListMini::-webkit-scrollbar:vertical {
	display: none;
}

.CardListMini::-webkit-scrollbar:horizontal {
	width: 2px;
	height: 6px;
	background-color: #212121;
}

.CardListMini::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: #555555;
}

.ChatContainer {
	margin: 0;
	padding: 0;
	height: 100%;
	padding: 0px !important;
}

.ChatWrapper {
	height: 100%;
	position: relative;
}

.ChatList {
	display: flex;
	flex-direction: column;
	max-height: 400px;
	min-height: 400px;
	width: 400px;
	max-width: 400px;
	min-width: 230px;
	overflow: auto;
	padding-left: 6px;
	padding-right: 6px;
}

.ChatList {
	background-color: #212121;
}

.ChatListStream {
	background-color: transparent;
	position: relative;
	/* margin-bottom: 60px; */
	/* max-height: 550px; */
	/* height: fit-content; */
}

.ChatList::-webkit-scrollbar {
	-webkit-appearance: none;
	background-color: #212121;
}

.ChatList::-webkit-scrollbar:vertical {
	width: 6px;
	background-color: #212121;
}

.ChatList::-webkit-scrollbar:horizontal {
	display: none;
}

.ChatList::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: #555555;
}

.CardListMini::-webkit-scrollbar {
	-webkit-appearance: none;
	background-color: #212121;
}

.CardListMini::-webkit-scrollbar:vertical {
	display: none;
}

.CardListMini::-webkit-scrollbar:horizontal {
	width: 2px;
	height: 6px;
	background-color: #212121;
}

.CardListMini::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: #555555;
}

.ChatListElem {
	display: flex;
	justify-content: center;
	flex-direction: column;
	word-wrap: break-word;
	font-size: 12px;
	font-weight: normal;
	padding: 0px !important;
	-webkit-align-items: unset !important;
	-webkit-box-align: unset !important;
	-ms-flex-align: unset !important;
	align-items: unset !important;
}

.ChatListElem_wrap {
	display: flex;
	justify-content: flex-start;
	/* background-color: #1a1a1a; */

	align-items: flex-end;
	border-radius: 10px;
	width: 100%;
}

.odd .ChatListElem_wrap {
	flex-direction: row-reverse;
}

.odd .ChatListElem_footer {
	align-self: self-end;
}

.odd .msg {
	align-self: flex-end;
}

.ChatListElem_wrap .msg {
	padding: 5px;
}

.chatUserData {
	display: flex;
	align-items: flex-end;
	flex-direction: row-reverse;
}

.userMessage {
	margin: 3px 5px;
	padding: 5px;
	background-color: #fff;
	color: #191919;
	max-width: 280px;
	min-width: 80px;
	width: 100%;
	border-radius: 5px;
}

.userLogo {
	width: 20px;
}

/* .emoji {

*/
.ChatListElem_footer {
	display: flex;
	align-items: center;
	margin-top: 20px;
	margin-right: 10px;
	margin-left: 10px;
}

.ChatListElem_footer svg {
	margin-left: 5px;
	margin-right: 5px;
}

.ChatListElem_wrap .msg {
	padding: 4px 16px 20px 12px;
	font-size: 12px;
	font-weight: medium;
	background: #234612;
	color: white;
	margin: 0px 10px 4px 10px;
	border-radius: 10px;
	height: fit-content;
	line-height: normal;
	overflow-wrap: anywhere;
	min-width: 120px;
	width: fit-content;
	position: relative;
}

.ChatListElem_wrap .deletedMsg {
	padding: 4px 16px 20px 12px;
	font-size: 12px;
	font-weight: medium;
	background: #234612;
	color: white;
	margin: 0px 10px 4px 10px;
	border-radius: 10px;
	height: fit-content;
	line-height: normal;
	overflow-wrap: anywhere;
	min-width: 120px;
	width: fit-content;
	position: relative;
	opacity: 0.6;
}

.ChatListElem_wrap .tip .deletedMsg {
	padding: 4px 16px 20px 12px;
	font-size: 12px;
	font-weight: medium;
	background: #ed2779 !important;
	color: white;
	margin: 0px 10px 4px 10px;
	border-radius: 10px;
	height: fit-content;
	line-height: normal;
	overflow-wrap: anywhere;
	min-width: 120px;
	width: fit-content;
	position: relative;
	opacity: 0.6;
}

.ChatListElem_wrap .time {
	color: #ffffff8e;
	font-size: 10px;
	position: absolute;
	right: 10px;
	bottom: 3px;
}

.ChatListElem_wrap .ban {
	color: red;
	font-size: 12px;
	position: absolute;
	left: 10px;
	bottom: 3px;
	opacity: 1 !important;
}

.message_wrap {
	display: flex;
	flex-direction: column;
}

.username {
	color: #77f73b;
	font-size: 12px;
}

.ChatListElem_wrap .username {
	color: #77f73b;
	font-size: 12px;
}

.animate.pop {
	animation-duration: 0.17s;
	animation-name: animate-pop;
	animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
}

@keyframes animate-pop {
	0% {
		opacity: 0;
		transform: scale(0.5, 0.5);
	}

	100% {
		opacity: 1;
		transform: scale(1, 1);
	}
}

.tip > .msg {
	background: #ed2779 !important;
	color: white !important;
	font-weight: medium !important;
}

.ImgPlace .vip {
	border: 2px dashed #ed2779;
}

.vip > .msg {
	background: #ffd800;
	color: black;
}

.ChatListElem_footer .vip {
	background: #ed2779 !important;
	color: white !important;
	border-radius: 10px;
	font-weight: bold;
	width: fit-content;
	margin-top: 5px;
	line-height: 1.5;
}

.emoji {
	border: 3px solid red;
}

/* .ImgPlace {
  display: flex;
<<<<<<< src/App.css
} */

.userLogo .img {
	width: 20px;
	border-radius: 50%;
}

.ImgPlace .img {
	border-radius: 50%;
}

.pop-up .MuiBackdrop-root {
	backdrop-filter: blur(15px);
	filter: blur(15px);
	-webkit-filter: blur(15px);
}

.modal {
	border-radius: 10px;
	background: #222222 0% 0% no-repeat padding-box;
	box-shadow: 0 0 2rem 12px rgb(0 0 0 / 50%);
	border: 1px solid rgb(18, 18, 18);
}

.MuiModal-root .BackButton {
	display: flex;
	/* justify-content: flex-end; */
	/* justify-items: flex-end; */
	/* padding: 0px 0px 0px; */
	/* margin: 0; */
	/* margin: -18px 321px -22px; */
	position: absolute;
	right: 0%;
	top: 0%;
}

.HideButton {
	display: flex !important;
	/* justify-content: flex-end; */
	/* justify-items: flex-end; */
	/* padding: 0px 0px 0px; */
	/* margin: 0; */
	/* margin: -18px 321px -22px; */
	position: absolute !important;
	right: 0%;
	top: 0%;
	color: white !important;
	padding: 0px 0px 0px !important;
	background-color: #555555 !important;
	opacity: 0.5;
	margin: 5px 4px 0px !important;
}

.bottomVideoBlock {
	-webkit-box-shadow: 0px 0px 35px 1px rgba(119, 247, 59, 1);
	-moz-box-shadow: 0px 0px 35px 1px rgba(119, 247, 59, 1);
	box-shadow: 0px 0px 35px 1px rgba(119, 247, 59, 1);
}

@media screen and (max-width: 1100px) {
	#root .tipsButtons {
		flex-direction: column-reverse;
		position: absolute;
	}
}

@media screen and (max-width: 500px) {
	.ModelProfilePage_elements {
		min-width: 170px;
		background-color: red;
		font-size: 10px !important;
	}

	.ModelProfilePage_row .content {
		min-width: 170px;
		font-size: 10px;
		padding-top: 4px;
	}

	.prevVideo_title {
		font-size: 15px !important;
	}

	.prevVideo_subtitle {
		font-size: 12px !important;
	}
}

.MuiFormHelperText-root {
	position: absolute;
	bottom: -20px;
	left: 0;
}

.smooth-image {
	transition: opacity 1s;
}

.image-visible {
	opacity: 1;
}

.image-hidden {
	opacity: 0;
}

.list-container {
	display: flex;
	font-size: 18px;
	background-color: #eee;
	flex-direction: column;
}

.item-container {
	background-color: #fff;
	border: 1px solid black;
	padding: 25px 70px;
	margin: 15px 50px;
}

@keyframes heartbeat {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.25);
	}

	100% {
		transform: scale(1);
	}
}

.online {
	color: red !important;
	margin-left: 5px;
	border-color: red !important;
	cursor: pointer;
	animation: heartbeat 1s infinite;
}

@keyframes recordheart {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}

.record {
	color: white !important;
	margin-left: 5px;
	border-color: white !important;
	cursor: pointer;
	animation: recordheart 1s infinite;
}

ReactTags__tags {
	position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
	width: 200px;
	border-radius: 2px;
	display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
	height: 31px;
	margin: 0;
	font-size: 12px;
	width: 100%;
	border: 1px solid #eee;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
	border: 1px solid #ddd;
	background: #63bcfd;
	color: white;
	font-size: 12px;
	display: inline-block;
	padding: 5px;
	margin: 0 5px;
	border-radius: 2px;
}

.ReactTags__selected a.ReactTags__remove {
	color: #aaa;
	margin-left: 5px;
	cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
	position: absolute;
}

.ReactTags__suggestions ul {
	list-style-type: none;
	box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
	background: white;
	width: 200px;
}

.ReactTags__suggestions li {
	border-bottom: 1px solid #ddd;
	padding: 5px 10px;
	margin: 0;
}

.ReactTags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
	background: #b7cfe0;
	cursor: pointer;
}

.ReactTags__remove {
	border: none;
	cursor: pointer;
	background: none;
	color: white;
}

.stats_type {
	width: 25%;
	display: flex;
	flex-direction: column !important;
	align-items: flex-start;
}

.Support_selector {
	display: flex;
	text-align: left;
	font-size: 1rem;
	font-weight: bold;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	justify-content: flex-start;
	width: 90%;
}

.LandingSelect {
	width: 100% !important;
	height: 35px;
	color: white !important;
}

.MuiPaper-root {
	background-color: #121212 !important;
}

.LandingSelect .MuiSelect-icon {
	color: white;
}

@media (min-width: 900px) {
	.navlinkName {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-content: flex-start;
		flex-direction: column;
	}
}

.list {
	display: flex;
	flex-wrap: wrap;
	user-select: none;
}

.item {
	position: relative;
	flex-shrink: 0;
	display: flex;
	margin: 8;
	cursor: grab;
	user-select: none;
	box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
}

.MuiButton-root.Menu_button {
	text-decoration: none;
	color: whitesmoke;
	font-size: 18px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px 16px;
	text-transform: unset;
}

.MuiButton-root.Menu_button {
	text-decoration: none;
	color: whitesmoke;
	font-size: 18px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px 16px;
	text-transform: unset;
}

.MuiButton-root.Menu_button:hover {
	background-color: #2e2e2e;
}

.MuiMenuItem-root.sidebar_menu_item {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 11px 0px;
	list-style: none;
	height: -moz-fit-content;
	height: fit-content;
	min-height: unset;
	width: 100%;
	color: white;
	background-color: #2e2e2e;
}

.MuiMenuItem-root.sidebar_menu_item:hover {
	color: #77f73b;
}

.MuiMenuItem-root.menu_active {
	color: black !important;
	background-color: #77f73b !important;
}

.Pick_round {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 650px;
	margin: 0;
	height: 100%;
	display: flex;
	flex-direction: row;
	background: black;
	border-radius: 5px;
	padding: 20px;
	gap: 10px;
}

.Voting_settings {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 1000px;
	margin: 0;
	height: 100%;
	display: flex;
	flex-direction: row;
	background: black;
	border-radius: 5px;
	padding: 20px 30px;
	gap: 20px;
}

.MuiGrid-root.Voting_switches {
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
}

.MuiButton-root.pick_round_button {
	width: 68px;
	height: 68px;
	background-color: #1d1d1d;
	color: white;
	font-weight: 400;
	font-size: 25px;
	border-radius: 10px;
}

.Magicx_text {
	margin: 0 !important;
	padding: 0;
	display: flex;
	align-items: center;
	flex-direction: row;
	min-width: 650px;
	color: white;
	flex-wrap: nowrap;
	padding-bottom: 5px;
}

.Voting_tricks {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 1500px;
	margin: 0;
	height: 100%;
	display: flex;
	flex-direction: column;
	background: black;
	border-radius: 5px;
	padding: 20px 30px;
	gap: 20px;
}

.MuiGrid-root.Voting_rows {
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: space-between;
}

.LandingSelect {
	width: 80% !important;
	height: 40px;
	background-color: #1d1d1d;
	color: white !important;
}

.LandingSelect .MuiSelect-icon {
	color: white;
}

.LandingSelect .MuiOutlinedInput-notchedOutline {
	top: 0px !important;
	border: none;
}

.inputBoxTrick {
	background-color: #1d1d1d;
	font-size: 20px;
	padding: 5px 8px 4px 8px;
	border-radius: 3px;
	color: white;
	border-width: 0px;
	width: 80%;
	min-width: 250px;
}

.inputBoxTrick .Mui-disabled {
	opacity: 1 !important;
	-webkit-text-fill-color: white !important;
}

.Tricks_proceed {
	width: 241px !important;
	min-width: 241px !important;
	height: 39px !important;
	min-height: 39px !important;
	color: white !important;
	border: 1px solid #707070 !important;
	border-radius: 5px !important;
	opacity: 1 !important;
	text-transform: unset !important;
	display: flex !important;
	justify-content: flex-start !important;
	position: relative;
	padding-left: 31px !important;
}

.Tricks_proceed .MuiButton-endIcon {
	position: absolute;
	right: 5px;

	display: flex;
}

.Magics_table .MuiTableCell-root {
	border: none !important;
	padding: 5px;
}

.Magics_table {
	overflow: hidden !important;
}

.Trick_status {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.Trick_status .MuiCheckbox-root {
	padding: 0px 5px 0px 0px;
}

.pop-up-camera .MuiBackdrop-root {
	background-color: black;
	opacity: 0.8 !important;
}

.tips_button .MuiDialog-paper {
	text-align: center !important;
	margin: 0 auto !important;
	background-image: unset !important;
	padding: 20px 15px;
	background: #212121 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 3px 20px #77f73b !important;
	border-radius: 10px !important;
	opacity: 1 !important;
	border: unset;
	width: 100%;
	max-width: 1300px;
	min-width: 1300px;
	// min-width:  fit-content;
	// max-width: fit-content;
}

.jwplayer.jw-flag-aspect-mode {
	border-radius: 10px;
}

.jw-error-msg {
	display: none !important;
}

.jw-controls-backdrop {
	background: black;
}

.canvasjs-stock-container > .canvasjs-chart-canvas {
	display: none !important;
}

.canvasjs-chart-panel,
.canvasjs-chart-canvas,
.canvasjs-navigator-panel,
.canvasjs-slider-panel {
	height: 100px !important;
}

.canvasjs-chart-credit {
	display: none;
}

.canvasjs-navigator-panel,
.canvasjs-chart-panel,
.canvasjs-slider-panel {
	width: 100% !important;
}

.canvasjs-chart-panel {
	position: initial;
}

// .canvasjs-navigator-panel{
//   display: none;
// }

.canvasjs-chart-canvas {
	width: 100% !important;
	border-radius: 10px;
	margin-top: 5px;
}

.btn-container-close {
	position: absolute;
	right: -20px;
	top: 13px;
}

.btn-container {
	position: absolute;
	right: -50px;
	top: 13px;
}

// .menu-dialog .MuiDialog-paper {
//   padding: 0px !important;
//   background-color: #000000 !important;
//   background-image: none !important;
// }

.filter_result {
	width: 100%;
	min-width: 180px;
	height: 35px;
	background-color: white;
	color: black !important;
}

.filter_result .MuiSelect-icon {
	color: black;
}

.filter_result .MuiOutlinedInput-notchedOutline {
	top: 0px !important;
	border: none;
}

.play-container {
	width: 800px;
	height: 600px;
	/* background-color: #000; */
	margin: 20px 0 0 100px;
	float: left;
}

.NewChatListElem_wrap,
.NewCommentListElem_wrap {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	width: 100%;
	gap: 15px;
}

#CommentList {
	overflow-y: auto;
}

.ChatListElem {
	display: flex;
	justify-content: center;
	flex-direction: column;
	word-wrap: break-word;
	font-size: 14px;
	font-weight: normal;
	padding: 10px 15px !important;
	-webkit-align-items: unset !important;
	-webkit-box-align: unset !important;
	-ms-flex-align: unset !important;
	align-items: unset !important;

	&.model {
		background-color: #3b1a27 !important;
	}
}

.more_button {
	background: #77f73b !important;
	border: 1px solid #77f73b !important;
	border-radius: 100px !important;
	color: black !important;
	padding: 5px 10px !important;
	text-transform: initial !important;
	// font-size:1rem !important;
	max-height: 30px !important;
}

.searchComment {
	border-radius: 10px;
	border: hidden;

	& .MuiOutlinedInput-notchedOutline {
		top: 0px !important;
		border-radius: 10px !important;
	}

	&
		.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
		.MuiOutlinedInput-notchedOutline {
		border-color: #77f73b !important;
	}
}

.VideoInput {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.VideoInput_input {
	display: none;
}

.VideoInput_footer {
	background: #eee;
	width: 100%;
	min-height: 40px;
	line-height: 40px;
	text-align: center;
}

.MultiSelectUI {
	width: 100%;
	background-color: #171717;
	border-radius: 5px;

	svg {
		color: #77f73b;
	}

	fieldset {
		border-color: transparent !important;

		&:hover {
			border-color: #77f73b !important;
		}
	}

	.MuiInputBase-root:hover {
		.MuiOutlinedInput-notchedOutline {
			border-color: #77f73b !important;
		}
	}

	.MuiInputLabel-root.Mui-focused {
		color: #77f73b;
		top: -6px;
	}

	.MuiList-root {
		background-color: #000;
	}
}
.custom-upload .ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn{
	display:none !important;
}

.show-more-button {
	display: flex;
	flex-basis: 100%;
	align-items: center;
	justify-content: center;
	margin: 8px 0px;
	width: 100%;
	button {
		color: white !important;
		font-weight: lighter;
		border: 1px solid rgba(160, 160, 160, 0.35);
		border-radius: 20px;
		font-size: 0.9rem;
		padding: 4px 40px;
	}

}