.custom-quill .ql-toolbar .ql-picker,
.custom-quill .ql-toolbar .ql-button {
	color: white !important;
}
.custom-quill .ql-toolbar .ql-picker,
.custom-quill .ql-toolbar .ql-button,
.custom-quill .ql-toolbar .ql-color-picker .ql-picker-item,
.custom-quill .ql-toolbar .ql-background-picker .ql-picker-item {
	color: white !important;
}
.custom-quill .ql-picker-item {
	color: black !important;
}
.custom-quill .ql-toolbar .ql-formats .ql-picker,
.custom-quill .ql-toolbar .ql-formats .ql-button {
	color: white !important;
}
.custom-quill .ql-snow .ql-stroke {
	fill: none;
	stroke: white !important;
}
.custom-quill .ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
	fill: white !important;
}
.custom-quill .ql-toolbar.ql-snow {
	border: unset !important;
}
